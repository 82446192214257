import React from 'react'
import { ReactComponent as Logo } from  '../logo.svg'
import Clock from '../components/Clock'
//import ScanCounter from '../components/ScanCounter'
//import SessionTable from '../components/SessionTable'

export default function Boxoffice() {
  return (
    <div className='boxoffice-wrapper'>
      <header>
        <h1 className='visually-hidden'>Ticket box office</h1>
        <Logo />
        <Clock />
      </header>
      <main>
      </main>
    </div>
  )
}
